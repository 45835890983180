<template>
    <div class="app-container">
        <div class="filter-container">
            <!-- <el-input clearable  v-model="query.userId" placeholder="用户ID"  style="width: 200px" class="filter-item" controls-position="right" ></el-input> -->
    
      
            <dataSelect style="width: 180px" class="filter-item"  v-model="query.brandCode" clearable :codeType="'brand'" placeholder="品牌"></dataSelect>

            <dataSelect style="width: 200px" class="filter-item"  v-model="query.countryCode"  clearable  :codeType="'countrys'"  placeholder="国家"></dataSelect>
                
               
         
            <el-date-picker
                class="filter-item"
                v-model="query.logintimeStartAndEnd"
                type="datetimerange"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
            >
            </el-date-picker>

            <dataSelect style="width: 200px" class="filter-item" v-model="query.status" clearable :codeType="'problem_status'" codePlaceholder="问题状态"></dataSelect>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery">
               查询
            </el-button>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px" size="small ">
                重置
            </el-button>

            <el-button class="filter-item"   type="primary" @click="handleExport"  style="margin-left: 10px" size="small"   v-loading.fullscreen.lock="fullscreenLoading">
                导出
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list"   border stripe style="width: 100%">
            <!-- <el-table-column label="用户ID" prop="userId" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.userId }}</span>
                </template>
            </el-table-column>
            <el-table-column label="用户账号" prop="accName" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.accName }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="发生时间" prop="createTime" align="center" :formatter="formatDate">
                <!-- <template slot-scope="{ row }"  >
                    <span>{{ new Date(row.createTime).toLocaleString() }}</span>
                </template> -->
            </el-table-column>
            <el-table-column label="国家" prop="countrycode" align="center">
                <template slot-scope="{ row }">
                    <span>
                        {{ $dict.getDictValue('countrys', row.countryCode) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="品牌" prop="brandCode" align="center">
                <template slot-scope="{ row }">
                    <span>
                        {{ $dict.getDictValue('brand', row.brandCode) }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="页面地址" prop="webAddress" align="center" width="200"></el-table-column>
            <el-table-column label="错误堆栈" prop="error" align="center" width="350">
                <template slot-scope="{ row }">
                    <el-tag size="medium" style="width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis" @click="openDetail(row)">{{
                        row.error
                    }}</el-tag>
                    
                </template>
            </el-table-column>

            <el-table-column label="问题状态" prop="status" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.status == 0" style="color: #1081de">
                        {{ $dict.getDictValue('problem_status', row.status) }}
                    </span>
                    <span v-if="row.status == 1" style="color: #ff9500">
                        {{ $dict.getDictValue('problem_status', row.status) }}
                    </span>
                    <span v-if="row.status == 2" style="color: #10bb27">
                        {{ $dict.getDictValue('problem_status', row.status) }}
                    </span>
                    <span v-if="row.status == 3" style="color: #333333">
                        {{ $dict.getDictValue('problem_status', row.status) }}
                    </span>
                    <span v-if="row.status == 4" style="color: #37679f">
                        {{ $dict.getDictValue('problem_status', row.status) }}
                    </span>
                </template>
            </el-table-column>

            <el-table-column label="操作时间" prop="updateTime" align="center" :formatter="formatDate" width='140'>

            </el-table-column>
            <el-table-column label="操作人" prop="updateByName" align="center"></el-table-column>
            <el-table-column label="备注" prop="remark" align="center"></el-table-column>

            <el-table-column label="操作" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button"> 回复 </el-button>
                </template>
            </el-table-column>

        </el-table>

        <el-pagination
            style="margin-top: 10px"
            background
            v-show="total > 0"
            @current-change="doQueryList"
            :current-page="query.page"
            :page-size="query.size"
            layout="total, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>

        <el-dialog title="查看日志详情" :visible.sync="diologVisible" v-if="diologVisible" width="1000px" :close-on-click-modal="false">
            <el-descriptions class="margin-top" :column="2" size="medium" border>
                <!-- <el-descriptions-item label='用户ID' :labelStyle="{'width' : '100px'}">{{ showItem.userId }}</el-descriptions-item>
                <el-descriptions-item label='用户账号' :labelStyle="{'width' : '100px'}">{{ showItem.accName }}</el-descriptions-item> -->
                <el-descriptions-item label='发生时间' :labelStyle="{'width' : '100px'}">{{new Date(showItem.createTime).toLocaleString()  }}</el-descriptions-item>
                <el-descriptions-item label='客户IP' :labelStyle="{'width' : '100px'}">{{ showItem.ipAddress }}</el-descriptions-item>
                <el-descriptions-item label='品牌' :labelStyle="{'width' : '100px'}">{{ showItem.brandCode }}</el-descriptions-item>
                <el-descriptions-item label='国家' :labelStyle="{'width' : '100px'}"> {{ $dict.getDictValue('countrys', showItem.countryCode) }}</el-descriptions-item>
                
                
                <el-descriptions-item label='页面地址' :span="2" :labelStyle="{'width' : '100px'}">{{ showItem.webAddress }}</el-descriptions-item>
            
                <el-descriptions-item label='错误堆栈' :span="2" :labelStyle="{'width' : '100px' }">
                    {{ showItem.error }}
                </el-descriptions-item>
              
            </el-descriptions>

            <el-form :model="webLogBean" label-position="left" style="margin-top: 20px" label-width="100px">
                <el-form-item label="问题状态">
                    <dataSelect
                            style="width: 200px"
                            v-model="webLogBean.status"
                            clearable
                            :codeType="'problem_status'"
                            placeholder="问题状态"
                    ></dataSelect>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                            v-model="webLogBean.remark"
                            placeholder="请填写备注"
                            type="textarea"
                            maxlength="500"
                            rows="5"
                            show-word-limit
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleSubmitWebLog()"> 确认 </el-button>
                <el-button @click="closeWebLogForm()"> 取消 </el-button>
            </div>
            
        </el-dialog>
    </div>
</template>

<script>

import dataSelect from '@/components/common/DataSelect.vue';
import moment from 'moment';

function initWebLogBean() {
    const addBean = {
        webLogId: null,
        status: null,
        remark: null
    };
    return addBean;
}


export default {
    name: 'WebLogTable',
    components: {
        dataSelect,
        moment
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            query: {
                page: 1,
                limit: 10,
                userId: null,
                brandCode: null,
                logintimeStartAndEnd: null,
                countryCode: null,
                status: null
            },
 
        
            value: '',
            showItem:{},
            diologVisible:false,
            webLogBean: initWebLogBean(),
        };
    },
    created(){
        this.handleQuery();
    },
    methods: {
 
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList(1);
        },
        /**
         * 表单重置
         */
        cleanQuery() {
            this.query = {};
            this.doQueryList(1);
        },
        /**
         * 执行列表查询
         */
        doQueryList(page) {
            this.query.page = page;
            
            if (this.query.logintimeStartAndEnd != null) {
                this.query.beginTime = moment(this.query.logintimeStartAndEnd[0]).subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
                this.query.endTime = moment(this.query.logintimeStartAndEnd[1]).subtract(8, 'hour').format('YYYY-MM-DD HH:mm:ss');
             
            } else {
                this.query.beginTime = null;
                this.query.endTime = null;
            }

            this.listLoading = true;
            this.$store.dispatch('getWebLogPage', this.query).then((res) => {
                this.list = res.list;
                this.total = res.total;
            }).finally(() => {
                this.listLoading = false;
            });
        },
        openDetail(row){
            this.diologVisible = true;
            this.showItem = row;
            this.handleUpdate(row);
        },
         /**
         * 时间戳转码
         */
		 formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        closeWebLogForm() {
            this.diologVisible = false;
            this.doQueryList(this.query.page);
        },
        /**
         * 执行回复确认
         */
        handleSubmitWebLog() {

            let param = JSON.parse(JSON.stringify(this.webLogBean));
            param.status = parseInt(param.status);
            this.$store.dispatch('replyWeblog', param).then((data) => {
                this.$message({
                    message: '编辑成功',
                    type: 'success'
                });
                this.doQueryList(this.query.page);

            }).finally(() => {
                this.diologVisible = false;
            });
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.showItem = row;
            this.resetWebLogBean();
            for (const key in initWebLogBean()) {
                this.webLogBean[key] = row[key];
            }
            this.webLogBean.status = this.webLogBean.status + '';
            console.log(this.webLogBean);
            this.diologVisible = true;
        },
        /**
         * 重置表单
         */
        resetWebLogBean() {
            this.webLogBean = initWebLogBean();
        },
        /**
         * 导出excel
         */
        handleExport() {
            this.query.fileName = 'webLog.xlsx';
            this.$confirm('是否确认导出文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.fullscreenLoading = true;
                this.$store.dispatch('exportWebLogExcel', this.query).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '导出成功'
                    });
                }).finally(() => {
                    this.fullscreenLoading = false;
                });
            }).catch(() => {
                this.$message({type: 'info',message: '已取消'});
            });
        },
    }
};
</script>
<style scoped>
.filter-container {
    padding: 20px;
}

.filter-item {
    margin-left: 10px;
}

</style>
