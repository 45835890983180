var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("dataSelect", {
            staticClass: "filter-item",
            staticStyle: { width: "180px" },
            attrs: { clearable: "", codeType: "brand", placeholder: "品牌" },
            model: {
              value: _vm.query.brandCode,
              callback: function($$v) {
                _vm.$set(_vm.query, "brandCode", $$v)
              },
              expression: "query.brandCode"
            }
          }),
          _c("dataSelect", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { clearable: "", codeType: "countrys", placeholder: "国家" },
            model: {
              value: _vm.query.countryCode,
              callback: function($$v) {
                _vm.$set(_vm.query, "countryCode", $$v)
              },
              expression: "query.countryCode"
            }
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "datetimerange",
              format: "yyyy-MM-dd HH:mm:ss",
              "value-format": "yyyy-MM-dd HH:mm:ss",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              align: "right"
            },
            model: {
              value: _vm.query.logintimeStartAndEnd,
              callback: function($$v) {
                _vm.$set(_vm.query, "logintimeStartAndEnd", $$v)
              },
              expression: "query.logintimeStartAndEnd"
            }
          }),
          _c("dataSelect", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              codeType: "problem_status",
              codePlaceholder: "问题状态"
            },
            model: {
              value: _vm.query.status,
              callback: function($$v) {
                _vm.$set(_vm.query, "status", $$v)
              },
              expression: "query.status"
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleQuery }
            },
            [_vm._v("\n           查询\n        ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: {
                icon: "el-icon-search",
                type: "primary",
                size: "small "
              },
              on: { click: _vm.cleanQuery }
            },
            [_vm._v("\n            重置\n        ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading.fullscreen.lock",
                  value: _vm.fullscreenLoading,
                  expression: "fullscreenLoading",
                  modifiers: { fullscreen: true, lock: true }
                }
              ],
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleExport }
            },
            [_vm._v("\n            导出\n        ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "发生时间",
              prop: "createTime",
              align: "center",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: { label: "国家", prop: "countrycode", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$dict.getDictValue("countrys", row.countryCode)
                          ) +
                          "\n                "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "品牌", prop: "brandCode", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$dict.getDictValue("brand", row.brandCode)
                          ) +
                          "\n                "
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "页面地址",
              prop: "webAddress",
              align: "center",
              width: "200"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "错误堆栈",
              prop: "error",
              align: "center",
              width: "350"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        staticStyle: {
                          width: "300px",
                          "white-space": "nowrap",
                          overflow: "hidden",
                          "text-overflow": "ellipsis"
                        },
                        attrs: { size: "medium" },
                        on: {
                          click: function($event) {
                            return _vm.openDetail(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.error))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "问题状态", prop: "status", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 0
                      ? _c("span", { staticStyle: { color: "#1081de" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$dict.getDictValue(
                                  "problem_status",
                                  row.status
                                )
                              ) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    row.status == 1
                      ? _c("span", { staticStyle: { color: "#ff9500" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$dict.getDictValue(
                                  "problem_status",
                                  row.status
                                )
                              ) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    row.status == 2
                      ? _c("span", { staticStyle: { color: "#10bb27" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$dict.getDictValue(
                                  "problem_status",
                                  row.status
                                )
                              ) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    row.status == 3
                      ? _c("span", { staticStyle: { color: "#333333" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$dict.getDictValue(
                                  "problem_status",
                                  row.status
                                )
                              ) +
                              "\n                "
                          )
                        ])
                      : _vm._e(),
                    row.status == 4
                      ? _c("span", { staticStyle: { color: "#37679f" } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.$dict.getDictValue(
                                  "problem_status",
                                  row.status
                                )
                              ) +
                              "\n                "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作时间",
              prop: "updateTime",
              align: "center",
              formatter: _vm.formatDate,
              width: "140"
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作人", prop: "updateByName", align: "center" }
          }),
          _c("el-table-column", {
            attrs: { label: "备注", prop: "remark", align: "center" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleUpdate(row)
                          }
                        }
                      },
                      [_vm._v(" 回复 ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        staticStyle: { "margin-top": "10px" },
        attrs: {
          background: "",
          "current-page": _vm.query.page,
          "page-size": _vm.query.size,
          layout: "total, prev, pager, next, jumper",
          total: _vm.total
        },
        on: { "current-change": _vm.doQueryList }
      }),
      _vm.diologVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "查看日志详情",
                visible: _vm.diologVisible,
                width: "1000px",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.diologVisible = $event
                }
              }
            },
            [
              _c(
                "el-descriptions",
                {
                  staticClass: "margin-top",
                  attrs: { column: 2, size: "medium", border: "" }
                },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "发生时间",
                        labelStyle: { width: "100px" }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          new Date(_vm.showItem.createTime).toLocaleString()
                        )
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { label: "客户IP", labelStyle: { width: "100px" } }
                    },
                    [_vm._v(_vm._s(_vm.showItem.ipAddress))]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { label: "品牌", labelStyle: { width: "100px" } }
                    },
                    [_vm._v(_vm._s(_vm.showItem.brandCode))]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: { label: "国家", labelStyle: { width: "100px" } }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$dict.getDictValue(
                              "countrys",
                              _vm.showItem.countryCode
                            )
                          )
                      )
                    ]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "页面地址",
                        span: 2,
                        labelStyle: { width: "100px" }
                      }
                    },
                    [_vm._v(_vm._s(_vm.showItem.webAddress))]
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "错误堆栈",
                        span: 2,
                        labelStyle: { width: "100px" }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.showItem.error) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    model: _vm.webLogBean,
                    "label-position": "left",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "问题状态" } },
                    [
                      _c("dataSelect", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          clearable: "",
                          codeType: "problem_status",
                          placeholder: "问题状态"
                        },
                        model: {
                          value: _vm.webLogBean.status,
                          callback: function($$v) {
                            _vm.$set(_vm.webLogBean, "status", $$v)
                          },
                          expression: "webLogBean.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请填写备注",
                          type: "textarea",
                          maxlength: "500",
                          rows: "5",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.webLogBean.remark,
                          callback: function($$v) {
                            _vm.$set(_vm.webLogBean, "remark", $$v)
                          },
                          expression: "webLogBean.remark"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmitWebLog()
                        }
                      }
                    },
                    [_vm._v(" 确认 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeWebLogForm()
                        }
                      }
                    },
                    [_vm._v(" 取消 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }